import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-layout.js";
import { Text, Button, Stack, Flex, Box, Heading, Badge } from '@chakra-ui/core';
import { Link } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Flex minHeight='70vh' alignItems='center' mdxType="Flex">
    <Flex flexDirection={['column', null, 'row']} alignItems='baseline' mdxType="Flex">
        <Stack alignItems='flex-start' spacing='2' mdxType="Stack">
            <Box mdxType="Box">
                <Text fontSize='sm' fontWeight='bold' color='gray.500' mdxType="Text">I AM</Text>
                <Heading fontSize='5xl' mdxType="Heading">Nekeisha Johnson</Heading>
            </Box>
            <Stack isInline alignItems='baseline' flexWrap='wrap' mdxType="Stack">
                <Badge fontSize='md' variantColor='teal' mb='2' mdxType="Badge">PhD Student</Badge>
                <Box fontSize='md' mdxType="Box">in</Box>
                <Badge fontSize='md' variantColor='teal' mb='2' mdxType="Badge">Physics Education Research</Badge>
                <Box fontSize='md' mdxType="Box">at</Box>
                <Badge fontSize='md' variantColor='teal' mdxType="Badge">North Dakota State University</Badge>
            </Stack>
        </Stack>
        <Stack spacing='2' mt={[8, null, 0]} mdxType="Stack">
            <Text fontSize='sm' fontWeight='bold' color='gray.500' mdxType="Text">LATEST PUBLICATION</Text>
            <Link to='/publications/PERC2020' mdxType="Link">
                <Stack as='article' bg='teal.50' p='6' border='1px' borderLeft='8px solid' borderColor='teal.300' borderRadius='md' mdxType="Stack">
                    <Box fontWeight='bold' fontSize='lg' mdxType="Box">Examining consistency of student errors in vector operations using module analysis</Box>
                    <Stack isInline alignItems='baseline' mdxType="Stack">
                        <Box mdxType="Box">Nekeisha&nbsp;Johnson, John&nbsp;B.&nbsp;Buncher</Box>
                        <Badge variant='solid' variantColor='teal' mdxType="Badge">PERC 2020</Badge>
                    </Stack>
                </Stack>
            </Link>
        </Stack>
    </Flex>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      